<template>
  <div>
    <b-row class="match-height">
      <b-col
        md="12"
        lg="12"
      >
        <b-card no-body>
          <b-card-body>
            <div class="home-top">
              <b-card-title class="home-title mb-0">Abatedor e Ultracongelador Copa</b-card-title>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="12"
        lg="4"
      >
        <b-card no-body>
          <b-card-body>
            <div class="home-img">
              <img src="https://fakeimg.pl/525x251/" alt="">
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        md="12"
        lg="8"
      >
        <b-card no-body class="home-status">
          <b-card-body>
            <b-row>
              <b-col class="status-side" xl="4">
                <b-card-title class="text-status mb-0">Temperatura</b-card-title>
                <div class="alert-temps icon-status">
                  <div class="alert-temp">
                      <vue-apex-charts
                        type="radialBar"
                        height="300"
                        :options="goalOverviewRadialBar.chartOptions"
                        :series="goalOverviewRadialBar.series"
                      />
                      <div class="temp-range">
                          <span>
                            {{ goal_overview.min_temp }}
                          </span>
                          <span>
                            {{ goal_overview.max_temp }}
                          </span>
                      </div>
                  </div>
                </div>
              </b-col>
              <b-col class="status-side" lg="4">
                <b-card-title class="text-status mb-0">Consumo</b-card-title>
                <div class="status-icons">
                  <span class="consumo-text-green">-0,02 kWh</span>
                  <div class="icon-status">
                    <span>0,92 kWh</span>
                    <img src="@/assets/images/icons/consumo-green.png" alt="">
                  </div>
                </div>
                <div class="mt-2 mb-2">
                  <b-card-title class="text-status mb-0">Alertas ativos</b-card-title>
                  <div class="home-alert">
                    <feather-icon
                      class="alert-icon"
                      icon="BellIcon"
                    />
                    <span>No active alerts</span>
                  </div>
                </div>
              </b-col>
              <b-col lg="4" class="status-side-2">
                <b-card-title class="text-status mb-0">Device status</b-card-title>
                <div class="status-icons">
                  <div class="icon-status">
                    <img src="@/assets/images/icons/ok.png" alt="">
                    <span>ok</span>
                  </div>
                </div>
                <div class="icon-vent-2 mr-0">
                  <img src="@/assets/images/icons/active-ventilation.png" alt="">
                  <b-button
                    class="vent-btn mr-0"
                    variant="none"
                  >
                    <span>Ver mais</span>
                  </b-button>
                </div>
              </b-col>
                <div class="info-btn">
                  <b-button
                    class="btn-sm temp-info-btn"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.left="'Custo e tempo total que o modo de conservação esteve ativo após concluído um ciclo. Para máx. eficiência reduza este valor.'"
                    variant="none"
                  >
                    <feather-icon
                      class="temp-info-icon"
                      icon="AlertCircleIcon"
                    />
                  </b-button>
                </div>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, 
  BCardSubTitle, BLink, VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      goal_overview: {
        min_temp: '0,0°C',
        max_temp: '3,0°C',
      },
      goalOverviewRadialBar: {
        series: [50],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ['#fff'],
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: -90,
              endAngle: 90,
              hollow: {
                size: '50%',
              },
              track: {
                background: 'rgba(255, 255, 255, 0.5)',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: 30,
                  color: '#fff',
                  fontSize: '18px',
                },
                value: {
                  offsetY: -15,
                  color: '#fff',
                  fontSize: '29px',
                },
              
              },
            },
          },
          fill: {
            colors: '#fff'
          },
          stroke: {
            lineCap: 'square',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
          labels: [''],
        },
      },
    }
  },
}
</script>
