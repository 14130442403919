<template>
  <b-card-code>
    <b-row class="d-none d-md-block">
      <b-col
        cols="12"
        xl="3"
      >
        <b-form-group>
          <div class="d-flex align-items-center">
              <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input placeholder="Search" />
              </b-input-group>
              <b-button
                class="search-btn mr-0"
                variant="none"
              >
                <span>GO</span>
              </b-button>
          </div>
        </b-form-group>
      </b-col>
      <b-col
        class="alerts-show"
        cols="12"
        md="9"
      >
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Show</label>
            <v-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-" 
            />
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <span
          v-if="props.column.field === 'example1'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.example1 }}</span>
        </span>

        <span
          v-else-if="props.column.field === 'example2'"
          class="text-nowrap"
        >
            <span class="text-nowrap">{{ props.row.example2 }}</span>
        </span>

        <span v-else-if="props.column.field === 'example3'">
          <span class="text-nowrap">{{ props.row.example3 }}</span>
        </span>

        <span
          v-else-if="props.column.field === 'example5'"
          class="text-nowrap"
        >
          <b-badge :variant="statusVariant(props.row.example5)">
            {{ props.row.example5 }}
          </b-badge>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-center flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to 7
            </span>
            <span class="text-nowrap"> of 100 entries </span>
          </div>
          <div class="d-flex flex-wrap">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="pagination-btn mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, 
  BDropdown, BDropdownItem, BInputGroup, BInputGroupPrepend, BInputGroupAppend,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import store from '@/store/index'
import { codeBasic } from './code'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BRow, 
    BCol, 
    BButton,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      perPage: 1,
      pageLength: 4,
      pageOptions: [3, 5, 10],
      dir: false,
      totalRows: 3,
      currentPage: 1,
      codeBasic,
      selected: { title: 'Select...' },
      option: [{ title: 'Option One' }, { title: 'Option Two' }, { title: 'Option Three' }, { title: 'Option Four' }],
      columns: [
        {
          label: 'DEVICE',
          field: 'example1',
        },
        {
          label: 'DISRIPTION',
          field: 'example2',
        },
        {
          label: 'START',
          field: 'example3',
        },
        {
          label: 'END',
          field: 'example4',
        },
        {
          label: 'STATUS',
          field: 'example5',
        },
      ],
      rows: [],
      searchTerm: '',
      exemple5: [{
        1: 'Alerta',
      },
      {
        1: 'light-alerta',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Alerta   : 'light-alerta',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    this.$http.get('/good-table/simulation2')
      .then(res => { this.rows = res.data })
  },
}
</script>